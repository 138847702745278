import React from 'react';
import { Link } from 'gatsby-plugin-modal-routing';

import cssButton from '../css/components/Button.module.css';
import '../css/utils/modal.css';

import css from '../css/components/Footer.module.css';

class Footer extends React.Component {
  render() {
    return (
      <footer className={`ta-center m-2 ${css.footer}`}>
        <div className={`subscribe`}>
          <Link
            to={`/newsletter`}
            asModal
            className={`raised ${css.item} ${cssButton.btn} ${cssButton.btnSmall} ${cssButton.red}`}
          >
            Subscribe
          </Link>
        </div>

        <div>
          <p className="fs-small raised">
            Feel free to send suggestions and comments to{' '}
            <a href="mailto:hello@cyberbiomes.org">hello@cyberbiomes.org</a>
          </p>
        </div>

        <div>
          <p className={`fs-tiny raised ${css.license}`}>
            An eco-speculative project curated by{' '}
            <a href="https://iljapanic.com" target="_blank">
              Ilja Panic
            </a>{' '}
            <br />
          </p>
        </div>
      </footer>
    );
  }
}

export default Footer;
