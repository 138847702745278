const particleNumber = 150;
const particleColor = '#d5d5d6';
const particleSpeed = 1;

module.exports = {
  particles: {
    number: { value: particleNumber, density: { enable: true, value_area: 800 } },
    color: { value: particleColor },
    shape: {
      type: 'circle',
      stroke: { width: 0, color: particleColor },
      polygon: { nb_sides: 5 },
      image: { src: 'img/github.svg', width: 100, height: 100 }
    },
    opacity: {
      value: 1,
      random: true,
      anim: { enable: true, speed: 1, opacity_min: 0, sync: false }
    },
    size: {
      value: 3.5,
      random: true,
      anim: { enable: false, speed: 4, size_min: 0.3, sync: false }
    },
    line_linked: { enable: true, distance: 50, color: particleColor, opacity: 0.4, width: 1 },
    move: {
      enable: true,
      speed: particleSpeed,
      direction: 'none',
      random: true,
      straight: false,
      out_mode: 'out',
      bounce: false,
      attract: { enable: false, rotateX: 600, rotateY: 600 }
    }
  },
  interactivity: {
    detect_on: 'canvas',
    events: {
      onhover: { enable: true, mode: 'grab' },
      onclick: { enable: true, mode: 'push' },
      resize: true
    },
    modes: {
      grab: { distance: 120, line_linked: { opacity: 1 } },
      bubble: { distance: 150, size: 0, duration: 2, opacity: 0, speed: 3 },
      repulse: { distance: 100, duration: 0.4, speed: 15 },
      push: { particles_nb: 8 },
      remove: { particles_nb: 2 }
    }
  },
  retina_detect: true
};
