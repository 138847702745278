import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import favicon from '../images/favicon.png';

function SEO({ description, lang, meta, keywords, permalink, cover, title }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription = description || data.site.siteMetadata.description;
        const metaTitle = title || data.site.siteMetadata.title;
        const permalinkUrl = permalink || 'https://cyberbiomes.org';
        const coverUrl = cover || 'https://cyberbiomes.org/cyberbiomes-cover.jpg';
        return (
          <Helmet
            htmlAttributes={{
              lang
            }}
            title={metaTitle}
          >
            <meta name="title" content={metaTitle} />
            <meta name="image" content={favicon} />
            <link rel="icon" type="image/png" href={favicon} />
            <meta
              name="keywords"
              content="biosemiotics, plant intelligence, inter-species, consciousness"
            />
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={metaTitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={permalinkUrl} />
            <meta property="og:image" content={coverUrl} />
            <meta property="og:description" content={metaDescription} />
            <meta name="twitter:title" content={metaTitle} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:card" content="summary" />
            <meta name="twitter:creator" content="@iljapanic" />
          </Helmet>
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

export default SEO;
