import React, { Component } from 'react';
import Particles from 'react-particles-js';

import particlesInitialParams from '../utils/particles';

import css from '../css/components/ParticlesBackground.module.css';

class ParticlesBackground extends Component {
  constructor(props) {
    super(props);
    this.state = { particleParams: particlesInitialParams, height: 2000 };
  }

  render() {
    return (
      <Particles
        // height={this.state.height}
        params={this.state.particleParams}
        canvasClassName={css.particlesCanvas}
      />
    );
  }
}

export default ParticlesBackground;
